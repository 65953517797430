'use client'
import React, { useState, useEffect } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from '../../ProductCard';
import useCustomSWR from '@/hook/useCustomSWR';
import { Skeleton } from '@mui/material';
import { useInView } from "react-intersection-observer";
import { Virtual, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Slider from '@/components/Slider';
import { v4 as uuidv4 } from 'uuid';
const CountryTemplate1 = ({ data }) => {
    const { heading, sub_heading, ids } = data;
    const [selectedId, setSelectedId] = useState(ids ? ids[0].id : '')
    const countryIds = JSON.stringify(ids?.map(d => d.id))

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });
    const { data: listData, isLoading } = useCustomSWR(`/v1/cms/get-filter-content?filter_type=country&ids=${countryIds}&limit=20`, inView)
    const { data: productData, isLoading: productLoading } = useCustomSWR(`/v1/products/get-all-products?filter[country_id][$eq]=${selectedId}&random=yes&page=1&limit=12&stock_product=true`, inView)

    if (!inView) return <div id='country' ref={ref}></div>;
    return (
        <div
            id='country'
            ref={ref}
            className='flex flex-col scroll-offset'
        >
            <div className='md:py-3 lg:py-5'>
                <Heading text={heading} />
                <SubHeading text={sub_heading} />
            </div>
            <div className='w-full '>
                <Slider gap={'gap-0'}>
                    {
                        listData?.response?.data?.map(c => (

                            <CountryCard
                                key={uuidv4()}
                                country={c}
                                length={ids.length}
                                style='shrink-0 h-40 sm:h-44 md:h-60 overflow-hidden w-32 sm:w-40 md:w-52'
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                            />

                        ))
                    }
                </Slider>
            </div>
            <div className='w-full mt-5'>
                <Swiper
                    modules={[Virtual]}
                    slidesPerView={6}
                    // centeredSlides={true}
                    spaceBetween={14}
                    virtual
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {productLoading &&
                        <div className='w-full h-80'>
                            {
                                Array.from({ length: 6 }, (_, index) => (
                                    <SwiperSlide key={uuidv4()}>

                                        <Skeleton
                                            animation='wave'
                                            variant='rectangular'
                                            width='100%'
                                            height='100%'
                                        />

                                    </SwiperSlide>
                                ))
                            }
                        </div>
                    }
                    {productData?.productList &&
                        productData?.productList?.length !== 0 &&
                        productData?.productList?.map((product, index) => {
                            return (
                                <SwiperSlide key={uuidv4()} virtualIndex={index}>
                                    <ProductCard
                                        key={product._id}
                                        isSwiper={true}
                                        product={product}
                                        style={'w-full h-52 md:h-80'}
                                        iconStyle={`w-7 h-7`}
                                        textSize={`text-sm`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
        </div >
    )
}

export default CountryTemplate1

const CountryCard = ({ country, style, length, selectedId, setSelectedId }) => {
    const { name, image, country_banner, used_country, _id } = country;
    const cardStyle = {
        backgroundImage: `url(${country_banner})`,
        backgroundSize: selectedId == _id && '200%', // Corrected
        transition: selectedId == _id && 'backgroundSize 0.5s', // Corrected
    };
    return (
        <>
            <div
                onMouseEnter={() => setSelectedId(_id)}
                className={`${style} ${selectedId == _id ? ' ' : 'grayscale filter'} relative flex cursor-pointer items-center justify-center bg-cover bg-center bg-no-repeat`}
                style={cardStyle}
            >
                <div className='relative z-10 flex flex-col text-xl font-bold text-white sm:text-2xl md:text-3xl'>
                    <p>{name}</p>
                    {used_country ? (<p className='text-center'>{used_country}</p>) : null}
                </div>
                <div className='absolute inset-0 bg-black opacity-30' />
            </div>
        </>
    );
};