'use client'
import React from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import useCustomSWR from '@/hook/useCustomSWR';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Skeleton } from '@mui/material';
import useDeviceType from '@/hook/useDeviceType';
import { v4 as uuidv4 } from 'uuid';
const HeroBanner = () => {
    const deviceType = useDeviceType()
    const { data, isLoading, isError, revalidate } = useCustomSWR(
        `/v1/cms/get-home-page-banner?title=hero-section-banner`,
        true
    );
    const { data: mobileBanner } = useCustomSWR(
        `/v1/cms/get-home-page-banner?title=hero-mobile-banner`,
        true
    );
    // function shuffleArray(array) {
    //     for (let i = array.length - 1; i > 0; i--) {
    //         // Pick a random index from 0 to i
    //         const randomIndex = Math.floor(Math.random() * (i + 1));

    //         // Swap the elements at i and randomIndex
    //         [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    //     }
    //     return array;
    // }
    if (!data?.response?.slider_images) {
        return (
            <Swiper
                modules={[Scrollbar, A11y, Autoplay, Pagination]} // Include Autoplay module here
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{ // Add autoplay configuration
                    delay: 6000, // Delay in milliseconds (3 seconds in this case)
                    disableOnInteraction: false, // Keep autoplay active after user interactions
                }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
                {[1, 2, 3].map(d => (
                    <SwiperSlide key={uuidv4()}>
                        <div className={`w-full h-80 md:h-96 lg:h-[85vh]`}>
                            <Skeleton
                                key={d}
                                animation='wave'
                                variant='rectangular'
                                width='100%'
                                height='100%'
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }

    const banner = deviceType == 'mobile' ? mobileBanner?.response?.slider_images : data?.response?.slider_images
    return (
        <>
            <div className='h-1  border-gray-300  shadow-mmd'>
            </div>
            <Swiper
                modules={[Scrollbar, A11y, Autoplay, Pagination]} // Include Autoplay module here
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{ // Add autoplay configuration
                    delay: 6000, // Delay in milliseconds (3 seconds in this case)
                    disableOnInteraction: false, // Keep autoplay active after user interactions
                }}
            // onSlideChange={() => console.log('slide change')}
            >
                {
                    banner?.map(brand => (
                        <SwiperSlide key={uuidv4()}>
                            <Link href={brand.brand_id} target='_blank'>
                                <img src={brand?.img_url} loading='lazy' className='w-full ' />
                            </Link>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    );
};

export default HeroBanner;
